<script lang="ts">import { generateRandomString } from '../../lib/utils/string.ts';
export let name = '';
export let label = 'Название';
export let checked = false;
const inputId = generateRandomString();
function handleChange() {
}
</script>

<label for={inputId} class="cs-switcher-label">
  <div class="cs-switcher-text">{label}</div>
  <div class="cs-switcher">
    <input
      bind:checked
      type="checkbox"
      id={inputId}
      {name}
      class="cs-switcher-input"
      on:change
    />
    <div class="cs-switcher-slider"></div>
  </div>
</label>

<style>
  .cs-switcher {
    width: 24px;
    height: 14px;
    position: relative;
    display: inline-flex;
    align-items: center;
  }

  .cs-switcher-label {
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .cs-switcher-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #000;
  }

  .cs-switcher-input {
    width: 0;
    height: 0;
    opacity: 0;
  }

  .cs-switcher-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50px;
    background-color: transparent;
    border: 2px solid rgba(0,0,0,.2);
    -webkit-transition: .4s;
    transition: .4s;
    cursor: pointer;
  }

  .cs-switcher-slider:before {
    content: '';
    height: 8px;
    width: 8px;
    position: absolute;
    top: 1px;
    left: 1px;
    background-color: rgba(0,0,0,.2);
    border-radius: 50%;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .cs-switcher-slider:after {
    content: '';
    height: 4px;
    width: 4px;
    position: absolute;
    top: 3px;
    left: 3px;
    background-color: #ececec;
    border-radius: 50%;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .cs-switcher-input:checked + .cs-switcher-slider {
    border-color: #000;
  }

  .cs-switcher-input:checked + .cs-switcher-slider:before {
    background-color: #000;
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }

  .cs-switcher-input:checked + .cs-switcher-slider:after {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
</style>
