<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount } from 'svelte';
import { useNavigate } from 'svelte-navigator';
import validate from '../lib/utils/validationRules';
import MainView from './Main.svelte';
import TemplateController from '../components/templates/TemplateController.svelte';
import { getTemplate, updateTemplate } from '../api';
export let templateId;
const navigate = useNavigate();
let template = {};
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    yield fetchTemplateById();
}));
function fetchTemplateById() {
    return __awaiter(this, void 0, void 0, function* () {
        const preparedId = Number(templateId);
        if (isNaN(preparedId)) {
            navigate('/templates');
        }
        try {
            template = yield getTemplate(`id=${preparedId}`);
            console.log(template);
        }
        catch (e) {
            console.error(`${e.name} ${e.message}`);
            navigate('/templates');
        }
    });
}
function getPayload($event) {
    const { font, cards, grid, search } = $event.detail;
    const payload = Object.assign(Object.assign({}, template), (validate.isNotUndefined(font) && { font }), (validate.isNotUndefined(cards) && { cards }), (validate.isNotUndefined(grid) && { grid }), (validate.isNotUndefined(search) && { search }));
    return payload;
}
function handleUpdate($event) {
    template = getPayload($event);
}
function handleSubmit($event) {
    return __awaiter(this, void 0, void 0, function* () {
        yield updateTemplate(getPayload($event));
        fetchTemplateById();
    });
}
</script>

<div class="main-container">
  {#if template.name}
    <TemplateController {template} on:update={handleUpdate} on:submit={handleSubmit} />
    <MainView {template} />
  {/if}
</div>

<style>
  .main-container {
    display: flex;
  }
</style>
