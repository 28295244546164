<script lang="ts">var _a;
import { createEventDispatcher } from 'svelte';
import throttle from '../lib/utils/throttle';
export let searchText;
export let template;
let styles;
const positionMap = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
};
const dispatch = createEventDispatcher();
const onInput = throttle(() => {
    dispatch('search', searchText.trim());
}, 1000);
$: {
    styles = Object.assign({}, (_a = template === null || template === void 0 ? void 0 : template.search) === null || _a === void 0 ? void 0 : _a.templateTwo);
}
</script>

<div
  class="search-container"
  style="justify-content: {positionMap[styles.inputHorizontalAlign]}"
>
  <div
    class="search-input"
    style="
      max-width: {styles.inputMaxWidth};
      padding: {styles.inputContainerPadding};
    "
  >
    <div
      class="wrapper"
      style="
        padding: {styles.inputPadding};
        border-color: {styles.inputBorderColor};
        border-radius: {styles.inputBorderRaduis};
        background-color: {styles.inputColor};
        box-shadow: {styles.inputBoxShadow};
      "
    >
      <input
        id="search"
        bind:value={searchText}
        type="text"
        placeholder={styles.inputPlaceholderText}
        style="
          font-size: {styles.inputFontSize};
          color: {styles.inputFontColor};
          --font-family: {template?.font};
          --color-placeholder: {styles.inputPlaceholderColor};
        "
        on:input={onInput}
      />
      <label
        for="search"
        class="search-button"
        style="
          margin-left: {styles.iconLeftMargin};
          background-image: url('{styles.buttonIcon}');
        "
        on:click={onInput}
      ></label>
    </div>
  </div>
</div>

<style>
  input {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #212121;
    background: transparent;
    outline: none;
    border: none;
    box-sizing: border-box;
  }

  input::placeholder {
    font-family: var(--font-family, inherit);
    color: var(--color-placeholder, #212121);
    text-align: left;
    opacity: .35;
  }

  .search-container {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    display: flex;
    justify-content: center;
  }

  .search-input {
    width: 100%;
    padding: 10px 0;
    transition: width .2s linear;
  }

  .wrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
    border: 1px solid #000;
    border-radius: 40px;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
  }

  .search-button {
    display: inline-block;
    width: 26px;
    height: 26px;
    background-color: transparent;
    background-image: url('/svg/search_24px.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all .4s linear;
    cursor: pointer;
  }
</style>
