<script lang="ts">import { Router, Route } from 'svelte-navigator';
import Main from './views/Main.svelte';
import Templates from './views/Templates.svelte';
import TemplateSettings from './views/TemplateSettings.svelte';
import PrivateRoute from './routes/PrivateRoute.svelte';
</script>

<Router primary={false}>
  <main>
    <Route path="/*" component="{Main}" />

    <Route path="templates/*">
      <PrivateRoute path="/">
        <Templates />
      </PrivateRoute>

      <PrivateRoute path=":id" let:params>
        <TemplateSettings templateId={params.id} />
      </PrivateRoute>
    </Route>
  </main>
</Router>

<style>
  main {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    font-family: Montserrat;
    background-color: transparent;
    overflow: hidden;
  }
</style>
