<script lang="ts">import { onMount, createEventDispatcher } from 'svelte';
import { useNavigate } from 'svelte-navigator';
import { fade } from 'svelte/transition';
import BaseButton from './BaseButton.svelte';
export let template;
let isEditing = false;
const navigate = useNavigate();
const dispatch = createEventDispatcher();
function handleRename() {
    isEditing = true;
}
function handleUpdate() {
    isEditing = false;
    dispatch('update', Object.assign({}, template));
}
function handleRemove() {
    dispatch('remove');
}
function handleRedirect() {
    if (isEditing) {
        return;
    }
    navigate(`/templates/${template.id}`);
}
</script>

<div class="container" transition:fade>
  <div on:click={handleRedirect}>
    <input
      bind:value={template.name}
      type="text"
      id={template.id}
      disabled={!isEditing}
    />
  </div>
  {#if isEditing}
    <BaseButton text="Сохранить" on:click={handleUpdate} />
  {:else}
    <BaseButton text="Переименовать" on:click={handleRename} />
  {/if}
  <BaseButton text="Удалить" on:click={handleRemove} />
</div>

<style>
  input {
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    background-color: #FFF;
    border: none;
    border-radius: 10px;
    outline: none;
  }

  input:placeholder {
    color: rgba(0, 0, 0, .25);
  }

  .container {
    display: flex;
    justify-content: space-between;
  }

  .container:not(:last-child) {
    margin-bottom: 10px;
  }

  .container input {
    flex: 1;
    margin-right: 10px;
  }

  .container :global(button):not(:last-child) {
    margin-right: 10px;
  }
</style>
