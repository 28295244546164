<div class="hamburger-container" on:click>
  <svg viewBox="0 0 20 14" width="20" height="14">
    <rect width="20" height="2"></rect>
    <rect y="6" width="20" height="2"></rect>
    <rect y="12" width="20" height="2"></rect>
  </svg>
  <!-- <svg viewBox="0 0 20 14" width="20" height="14">
    <line id="nav-line-1" x1="8" x2="56" y1="16" y2="16" class="nav-line" />
    <line id="nav-line-2" x1="8" x2="56" y1="32" y2="32" class="nav-line" />
    <line id="nav-line-3" x1="8" x2="56" y1="48" y2="48" class="nav-line" />

    <line x1="16" x2="48" y1="16" y2="48" class="cross-line" />
    <line x1="16" x2="48" y1="48" y2="16" class="cross-line" />

    <rect width="42" height="42" x="11" y="11" class="rect" />
  </svg> -->
</div>

<style>
  .hamburger-container {
    display: flex;
    cursor: pointer;
  }
</style>
