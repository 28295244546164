<script lang="ts">import { createEventDispatcher } from 'svelte';
import BaseButton from './BaseButton.svelte';
import TemplateAction from './TemplateAction.svelte';
export let templates = [];
const dispatch = createEventDispatcher();
</script>

<div>
  <div class="templates-controller">
    {#each templates as template, index}
      <svelte:component
        this={TemplateAction}
        {template}
        on:update={(event) => dispatch('update', event.detail)}
        on:remove={() => dispatch('remove', template)}
      />
    {/each}
  </div>
  <div>
    <BaseButton text="Создать новый" on:click={() => dispatch('add')} />
  </div>
</div>

<style>
  .templates-controller {
    margin-bottom: 10px;
  }
</style>
