<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount, createEventDispatcher } from 'svelte';
import BaseInput from '../BaseInput.svelte';
import BaseButton from '../BaseButton.svelte';
import throttle from '../../../lib/utils/throttle';
export let content = '';
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    if (!content) {
        return;
    }
    console.log('content', content);
    connectFontInHTML();
}));
const dispatch = createEventDispatcher();
const handleInput = throttle(connectFontInHTML, 500);
function connectFontInHTML() {
    insertFontToHTML();
}
function getFontSource() {
    return `https://fonts.googleapis.com/css?family=${content}:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,&amp;display=swap&amp;subset=cyrillic`;
}
function insertFontToHTML() {
    const link = document.createElement('link');
    link.href = getFontSource();
    link.rel = 'stylesheet';
    document.getElementsByTagName('head')[0].appendChild(link);
}
function handleSubmit() {
    dispatch('submit', { font: content });
}
</script>

<div class="font-container">
  <h2>Шрифты</h2>

  <BaseInput
    bind:value={content}
    label={'Подключенный'}
    name={'font'}
    on:input={handleInput}
  />
  <BaseButton on:click={handleSubmit} text="Сохранить" />
</div>

<style>
  h2 {
    margin: 0 0 20px 0;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #000;
  }

  .font-container :global(input) {
    margin-bottom: 10px;
  }
</style>
