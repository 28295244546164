<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount, createEventDispatcher } from 'svelte';
import validate from '../../../lib/utils/validationRules';
import throttle from '../../../lib/utils/throttle';
import BaseInput from '../BaseInput.svelte';
import BaseUploader from '../BaseUploader.svelte';
import BaseSwitcher from '../BaseSwitcher.svelte';
import BaseButton from '../BaseButton.svelte';
export let content;
let form = {
    containerMaxWidth: 980,
    maxItemsInRow: 4,
    gridGap: '20px',
    gridGapPhones: '10px',
    mixWidthToTransform: '12em',
    productCountToStep: 20,
    loadingImage: 'smile.gif',
    cardHeightFixed: false,
    showMore: false,
    loaderButtonText: 'Показать ещё',
    loaderButtonFontWeight: 500,
    loaderButtonFontSize: '14px',
    loaderButtonMargin: '30px 0',
    loaderButtonFontColor: '#000000',
    loaderButtonColor: '#FFFFFF',
    loaderButtonBorderColor: '#00000032',
    loaderButtonBorderSize: '1px',
    loaderButtonBorderRadius: '10px',
    loaderButtonAlign: 'center',
    loaderButtonPadding: '8px 15px',
};
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    form = Object.assign(Object.assign({}, form), content);
}));
const dispatch = createEventDispatcher();
const handleInput = throttle(() => {
    console.log('handleInput');
    dispatch('update', { grid: Object.assign({}, form) });
}, 500);
function handleSubmit() {
    dispatch('submit', { grid: Object.assign({}, form) });
}
</script>

<div class="form-container">
  <h2>Сетка</h2>

  <BaseInput
    bind:value={form.containerMaxWidth}
    label="container, max-width"
    name="container-max-width"
    on:input={handleInput}
  />
  <BaseInput
    bind:value={form.maxItemsInRow}
    label="max items in row"
    name="max-items-in-row"
    on:input={handleInput}
  />
  <BaseInput
    bind:value={form.gridGap}
    label="--grid-gap"
    name="grid-gap"
    on:input={handleInput}
  />
  <BaseInput
    bind:value={form.gridGapPhones}
    label="--grid-gap phones"
    name="grid-gap-phones"
    on:input={handleInput}
  />
  <BaseInput
    bind:value={form.mixWidthToTransform}
    label="Минимальная ширина карточки для перестройки в ленту"
    name="mix-width-to-transform"
    on:input={handleInput}
  />
  <BaseInput
    bind:value={form.productCountToStep}
    label="Количество товаров на шаг"
    name="product-count-to-step"
    on:input={handleInput}
  />
  <BaseUploader
    bind:value={form.loadingImage}
    label="Анимация подгрузки"
    name="loading-image"
    on:upload={$event => {
      form.loadingImage = $event.detail.link
      handleInput()
    }}
  />
  <BaseSwitcher
    bind:checked={form.cardHeightFixed}
    label="Фикс высоты карточки"
    name="card-height-fixed"
    on:change={handleInput}
  />

  <BaseSwitcher
    bind:checked={form.showMore}
    label='Кнопка "Показать ещё"'
    name="show-more"
    on:change={handleInput}
  />
  {#if form.showMore}
    <BaseInput
      bind:value={form.loaderButtonText}
      label="Текст на кнопке"
      name="loader-button-text"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.loaderButtonFontWeight}
      label="button, font-weight"
      name="loader-button-font-weight"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.loaderButtonFontSize}
      label="button, font-size"
      name="loader-button-font-size"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.loaderButtonMargin}
      label="margin"
      name="loader-button-margin"
      placeholder="none"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.loaderButtonFontColor}
      label="button, font-color"
      name="loader-button-font-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.loaderButtonColor}
      label="button, color"
      name="loader-button-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.loaderButtonBorderColor}
      label="border, color"
      name="loader-button-border-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.loaderButtonBorderSize}
      label="border, size"
      name="loader-button-border-size"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.loaderButtonBorderRadius}
      label="border-radius, size"
      name="loader-button-border-radius"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.loaderButtonAlign}
      label="button align"
      name="loader-button-align"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.loaderButtonPadding}
      label="padding"
      name="loader-button-padding"
      placeholder="none"
      on:input={handleInput}
    />
  {/if}

  <BaseButton
    text="Сохранить"
    on:click={handleSubmit}
  />
</div>

<style>
  h2 {
    margin: 0 0 20px 0;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #000;
  }

  .form-container :global(input),
  .form-container :global(.input) {
    margin-bottom: 10px;
  }

  .form-container :global(.cs-switcher-label) {
    width: 100%;
    margin-bottom: 10px;
  }
</style>
