<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount, createEventDispatcher } from 'svelte';
import throttle from '../../../lib/utils/throttle';
import BaseInput from '../BaseInput.svelte';
import BaseSelect from '../BaseSelect.svelte';
import BaseUploader from '../BaseUploader.svelte';
import BaseButton from '../BaseButton.svelte';
export let content;
let templates = [
    'Шаблон №1',
    'Шаблон №2',
];
let form = {
    selectedTemplate: 'Шаблон №1',
    templateOne: {
        buttonVerticalAlign: 'bottom',
        buttonHorizontalAlign: 'right',
        buttonMargin: '40',
        buttonPadding: '30px',
        buttonBorderRadius: '40px',
        buttonIcon: 'https://goods.dobro.market/svg/search_24px.svg',
        buttonButtonColor: '#000000',
        buttonBorderColor: '#000000',
        buttonBoxShadow: '0px 2px 1px rgba(0, 0, 0, 0.25)',
        buttonButtonHoverColor: '#000000',
        buttonButtonHoverBorderColor: '#000000',
        buttonHoverBoxShadow: '0px 2px 1px rgba(0, 0, 0, 0.25)',
        buttonButtonActiveColor: '#000000',
        buttonButtonActiveBorderColor: '#000000',
        buttonActiveBoxShadow: '0px 2px 1px rgba(0, 0, 0, 0.25)',
        inputMaxWidth: '920px',
        inputPadding: '14px 34px 14px 16px',
        inputBorderRaduis: '40px',
        inputFontSize: '14px',
        inputPlaceholderText: 'Что ищешь ты?',
        inputPlaceholderColor: '#212121',
        inputColor: '#FFFFFF',
        inputFontColor: '#212121',
        inputBorderColor: 'rgba(0, 0, 0, 0.5)',
        inputBoxShadow: '0px 2px 1px rgba(0, 0, 0, 0.25)',
        iconLeftMargin: '10px',
    },
    templateTwo: {
        buttonIcon: 'https://goods.dobro.market/svg/search_24px.svg',
        inputHorizontalAlign: 'center',
        inputMaxWidth: '920px',
        inputPadding: '14px 34px 14px 16px',
        iconLeftMargin: '10px',
        inputContainerPadding: '20px 0',
        inputFontSize: '14px',
        inputPlaceholderText: 'Что ищешь ты?',
        inputPlaceholderColor: '#212121',
        inputColor: '#FFFFFF',
        inputFontColor: '#212121',
        inputBorderColor: 'rgba(0, 0, 0, 0.5)',
        inputBorderRaduis: '40px',
        inputBoxShadow: '0px 2px 1px rgba(0, 0, 0, 0.25)',
    },
};
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    form = Object.assign(Object.assign({}, form), content);
}));
const dispatch = createEventDispatcher();
const handleInput = throttle(() => {
    dispatch('update', { search: Object.assign({}, form) });
}, 500);
function handleSelect(data) {
    dispatch('update', { search: Object.assign({}, form) });
}
function handleSubmit() {
    dispatch('submit', { search: Object.assign({}, form) });
}
</script>

<div class="form-container">
  <h2>Представление поиска</h2>

  <BaseSelect
    bind:value={form.selectedTemplate}
    options={templates}
    label="Шаблон"
    name="search-template"
    on:change={handleSelect}
  />

  {#if form.selectedTemplate === 'Шаблон №1'}
    <h3>Кнопка</h3>
    <BaseSelect
      bind:value={form.templateOne.buttonVerticalAlign}
      options={['top', 'bottom']}
      label="Vertical-align"
      name="button-vertical-align"
      on:change={handleSelect}
    />
    <BaseSelect
      bind:value={form.templateOne.buttonHorizontalAlign}
      options={['left', 'right']}
      label="Align"
      name="button-horizontal-align"
      on:change={handleSelect}
    />
    <BaseInput
      bind:value={form.templateOne.buttonMargin}
      label="Отступы от краев, px"
      name="button-margin"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.buttonPadding}
      label="Внутренние отступы"
      name="button-padding"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.buttonBorderRadius}
      label="Скругление"
      name="button-border-radius"
      on:input={handleInput}
    />
    <BaseUploader
      bind:value={form.templateOne.buttonIcon}
      label="Иконка"
      name="button-icon"
      on:upload={$event => {
        form.templateOne.buttonIcon = $event.detail.link
        handleInput()
      }}
    />
    <BaseInput
      bind:value={form.templateOne.buttonButtonColor}
      label="Цвет кнопки по умолчанию"
      name="button-button-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.buttonBorderColor}
      label="Бордер по умолчанию"
      name="button-border-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.buttonBoxShadow}
      label="Тень по умолчанию"
      name="button-box-shadow"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.buttonButtonHoverColor}
      label="Цвет при наведении"
      name="button-button-hover-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.buttonButtonHoverBorderColor}
      label="Бордер при наведении"
      name="button-button-hover-border-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.buttonHoverBoxShadow}
      label="Тень при наведении"
      name="button-hover-box-shadow"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.buttonButtonActiveColor}
      label="Цвет при нажатии"
      name="button-button-active-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.buttonButtonActiveBorderColor}
      label="Бордер при нажатии"
      name="button-button-active-border-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.buttonActiveBoxShadow}
      label="Тень при нажатии"
      name="button-active-box-shadow"
      on:input={handleInput}
    />

    <h3>Поле ввода</h3>
    <BaseInput
      bind:value={form.templateOne.inputMaxWidth}
      label="Максимальная ширина"
      name="input-max-width"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.inputPadding}
      label="Внутренние отступы"
      name="input-padding"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.iconLeftMargin}
      label="Icon Left Margin"
      name="icon-left-margin"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.inputBorderRaduis}
      label="Скругление углов"
      name="input-border-radius"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.inputFontSize}
      label="Размер текста"
      name="input-font-size"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.inputPlaceholderText}
      label="Плейсхолдер"
      name="input-placeholder-text"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.inputPlaceholderColor}
      label="Цвет плейсхолдера"
      name="input-placeholder-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.inputColor}
      label="Цвет поля"
      name="input-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.inputBorderColor}
      label="Цвет бордера"
      name="input-border-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.inputFontColor}
      label="Цвет шрифта"
      name="input-font-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateOne.inputBoxShadow}
      label="Тень по умолчанию"
      name="input-shadow"
      on:input={handleInput}
    />
  {:else }
    <BaseSelect
      bind:value={form.templateTwo.inputHorizontalAlign}
      options={['left', 'center', 'right']}
      label="Align"
      name="button-horizontal-align"
      on:change={handleSelect}
    />
    <BaseInput
      bind:value={form.templateTwo.inputMaxWidth}
      label="Максимальная ширина"
      name="input-max-width"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateTwo.inputContainerPadding}
      label="Container Padding"
      name="input-container-padding"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateTwo.inputPadding}
      label="Input Padding"
      name="input-padding"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateTwo.iconLeftMargin}
      label="Icon Left Margin"
      name="icon-left-margin"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateTwo.inputFontSize}
      label="Размер текста"
      name="input-font-size"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateTwo.inputPlaceholderText}
      label="Плейсхолдер"
      name="input-placeholder-text"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateTwo.inputPlaceholderColor}
      label="Цвет плейсхолдера"
      name="input-placeholder-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateTwo.inputColor}
      label="Цвет поля"
      name="input-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateTwo.inputFontColor}
      label="Цвет шрифта"
      name="input-font-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateTwo.inputBorderColor}
      label="Цвет бордера"
      name="input-border-color"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateTwo.inputBorderRaduis}
      label="Скругление углов"
      name="input-border-radius"
      on:input={handleInput}
    />
    <BaseInput
      bind:value={form.templateTwo.inputBoxShadow}
      label="Input Shadow"
      name="input-shadow"
      on:input={handleInput}
    />
    <BaseUploader
      bind:value={form.templateTwo.buttonIcon}
      label="Иконка"
      name="button-icon"
      on:upload={$event => {
        form.templateTwo.buttonIcon = $event.detail.link
        handleInput()
      }}
    />
  {/if}

  <BaseButton
    text="Сохранить"
    on:click={handleSubmit}
  />
</div>

<style>
  h2 {
    margin: 0 0 20px 0;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #000;
  }

  h3 {
    margin: 10px 0;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #000;
  }

  .form-container :global(.container) {
    margin-bottom: 10px;
  }

  .form-container :global(.cs-switcher-label) {
    width: 100%;
    margin-bottom: 10px;
  }
</style>
