<script lang="ts">export let size = 40;
export let color = '#212121';
export let unit = 'px';
export let template;
const range = (size, startAt = 0) => [...Array(size).keys()].map(i => i + startAt);
</script>


<div class="spinner-container">
  {#if template?.grid?.loadingImage}
    <div class="loading-image">
      <img src="{template.grid.loadingImage}" alt="" />
    </div>
  {:else}
    <div class="wrapper" style="--size: {size}{unit}; --color: {color}">
      {#each range(6, 0) as version}
        <div
          class="bar"
          style="left: {version * (size / 5 + (size / 15 - size / 100))+unit}; animation-delay: {version * 0.15}s;"
        ></div>
      {/each}
    </div>
  {/if}
</div>

<style>
  .spinner-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading-image {
    width: 100px;
  }

  .loading-image img {
    width: 100%;
  }

  .wrapper {
    width: calc(var(--size) * 2.5);
    height: var(--size);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 2px;
  }

  .bar {
    width: calc(var(--size) / 5);
    height: calc(var(--size) / 10);
    margin-top: calc(var(--size) - var(--size) / 10);
    position: absolute;
    top: calc(var(--size) / 10);
    transform: skewY(0deg);
    background-color: var(--color);
    animation: motion 1.25s ease-in-out infinite;
  }

  @keyframes motion {
    25% {
      transform: skewY(25deg);
    }
    50% {
      height: 100%;
      margin-top: 0;
    }
    75% {
      transform: skewY(-25deg);
    }
  }
</style>
