<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount, onDestroy } from 'svelte';
import Spinner from '../components/Spinner.svelte';
import Menu from '../components/Menu.svelte';
import SearchFixed from '../components/SearchFixed.svelte';
import SearchInput from '../components/SearchInput.svelte';
import Catalog from '../catalog/Catalog.svelte';
import serializeParams from '../lib/utils/url';
import validate from '../lib/utils/validationRules';
import { fetchMakerCategories, fetchBillCategories, fetchBills, fetchMakers, getTemplateByName } from '../api';
import { BILLS_TYPE, MAKERS_TYPE, FILTER_ALL } from '../const';
export let template = {};
// TODO: внедрить после презентации
// interface Catalog {
//   [page: number]: 1,
//   [count: number]: 1,
//   [list: array]: [],
//   [loading: boolean]: false,
//   [countPerPage: number]: 12,
//   [searchText: string]: '',
//   [filterBy: string]: 'all',
//   [lastDataChunk: array]: [],
//   [type: string]: 'bills',
// }
let categories = [];
let catalog = {
    page: 1,
    items: [],
    loading: false,
    searchText: '',
    filterBy: FILTER_ALL,
    type: BILLS_TYPE,
    lastDataChunk: [],
    countPerPage: 0,
    offset: 0,
    categories: [],
};
let iframeHeight, isLastDataChunk;
$: {
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onScroll);
    isLastDataChunk = catalog.lastDataChunk.length < catalog.countPerPage;
}
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield loadTemplate();
        yield loadData();
    }
    catch (error) {
        console.error(error);
    }
}));
onDestroy(() => {
    window.removeEventListener('scroll', onScroll);
    window.removeEventListener('resize', onScroll);
});
function getPayload() {
    const { value: search } = document.getElementById('search') || { value: catalog.searchText };
    const { value: categories } = document.getElementById('categories') || {};
    const { value: projects } = document.getElementById('projects') || {};
    const templateId = template.id || '';
    console.log('getPayload::templateId', templateId, validate.isNumber(templateId));
    const getParams = Object.assign((validate.isNotEmptyString(search) && { search }), (validate.isNotEmptyString(categories) && { categories }), (validate.isNotEmptyString(projects) && { projects }), (validate.isNumber(templateId) && { templateId }), { offset: catalog.offset });
    return serializeParams(getParams);
}
function resetData() {
    catalog.items = [];
    catalog.offset = 0;
}
function resetFilters() {
    catalog.searchText = '';
    catalog.filterBy = FILTER_ALL;
}
function loadData() {
    return __awaiter(this, void 0, void 0, function* () {
        catalog.loading = true;
        yield loadBills();
        catalog.loading = false;
    });
}
function loadBills() {
    return __awaiter(this, void 0, void 0, function* () {
        const payload = getPayload();
        const bills = yield fetchBills(payload);
        const categories = yield fetchBillCategories();
        catalog.items.push(...bills.result);
        catalog.lastDataChunk = bills.result;
        catalog.countPerPage = bills.countPerPage;
        catalog.offset = bills.offset;
        catalog.categories = categories;
    });
}
function onChangeTab(event) {
    return __awaiter(this, void 0, void 0, function* () {
        resetData();
        resetFilters();
        const tabCode = event.detail;
        catalog.type = tabCode;
        yield loadData();
    });
}
function onSearch(event) {
    return __awaiter(this, void 0, void 0, function* () {
        resetData();
        const searchPhrase = event.detail;
        catalog.searchText = searchPhrase;
        yield loadData();
    });
}
function onFilter(event) {
    return __awaiter(this, void 0, void 0, function* () {
        resetData();
        const filterData = event.detail;
        catalog.filterBy = filterData;
        yield loadData();
    });
}
function onScroll(event) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        if (catalog.loading) {
            return;
        }
        if (isLastDataChunk) {
            return;
        }
        if ((_a = template === null || template === void 0 ? void 0 : template.grid) === null || _a === void 0 ? void 0 : _a.showMore) {
            return;
        }
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset || 0;
        const offset = scrollTop + window.innerHeight + 20;
        const height = document.documentElement.offsetHeight;
        if (offset >= height) {
            yield loadData();
        }
    });
}
function loadTemplate() {
    return __awaiter(this, void 0, void 0, function* () {
        const { value: templateName } = document.getElementById('template') || {};
        if (validate.isUndefined(templateName)) {
            return;
        }
        try {
            template = yield getTemplateByName(templateName);
        }
        catch (e) {
            console.error(`${e.name} ${e.message}`);
        }
    });
}
</script>

<div
  class="container"
  style="font-family: {template?.font}"
  on:scroll={onScroll}
>
  {#if template?.search?.selectedTemplate}
    {#if template.search.selectedTemplate === 'Шаблон №1'}
      <SearchFixed
        searchText={catalog.searchText}
        {template}
        on:search={onSearch}
      />
    {:else}
      <SearchInput
        searchText={catalog.searchText}
        {template}
        on:search={onSearch}
      />
    {/if}
  {/if}

  <Catalog
    items={catalog.items}
    type={catalog.type}
    isFilled={isLastDataChunk}
    {template}
    on:show-more={loadData}
  />

  {#if catalog.loading}
    <Spinner {template} />
  {/if}
</div>

<style>
  .container {
    width: 100%;
  }
</style>
