<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { onMount } from 'svelte';
import { Link, useNavigate } from 'svelte-navigator';
import TemplatesController from '../components/templates/TemplatesController.svelte';
import { allTemplates, createTemplate, updateTemplate, removeTemplateById } from '../api';
const navigate = useNavigate();
let templates = [];
onMount(() => __awaiter(void 0, void 0, void 0, function* () {
    yield fetchTemplates();
}));
function fetchTemplates() {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            templates = yield allTemplates();
        }
        catch (error) {
            console.error(`${error.name}: ${error.message}`);
            navigate('/');
        }
        console.log(templates);
    });
}
function addTemplate() {
    return __awaiter(this, void 0, void 0, function* () {
        yield createTemplate(name = 'unknown');
        fetchTemplates();
    });
}
function handleUpdate(event) {
    return __awaiter(this, void 0, void 0, function* () {
        const template = event.detail;
        console.log('template', template);
        yield updateTemplate(template);
        fetchTemplates();
    });
}
function handleRemove(event) {
    return __awaiter(this, void 0, void 0, function* () {
        const template = event.detail;
        const templateId = template.id;
        yield removeTemplateById(templateId);
        fetchTemplates();
    });
}
</script>

<div class="main-container">
  <div class="templates-container">
    <TemplatesController
      templates={templates}
      on:add={addTemplate}
      on:update={handleUpdate}
      on:remove={handleRemove}
    />
  </div>
</div>

<style>
  .main-container {
    min-height: 100vh;
    background-color: rgba(33, 33, 33, .1);
  }

  .templates-container {
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
  }
</style>
