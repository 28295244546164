<script lang="ts">export let imageLink;
export let selfEmployed = false;
export let template;
</script>

<div
  class="image-overlay {template?.proportionalPreview ? 'proportional-overlay' : ''}"
  style="background-color: {template?.imageOverlay}"
>
  {#if template?.proportionalPreview}
    <img
      src="{imageLink ? imageLink : template?.imageEmpty}"
      class="proportional-image"
      style="
        aspect-ratio: {template?.aspectRatio};
        object-fit: {template?.objectFit};
      "
      alt=""
    />
  {:else}
    {#if imageLink}
      <div
        class="image"
        style="background-image: url({imageLink});"
      ></div>
    {:else}
      <div
        class="image-empty"
        style="background-image: url({template?.imageEmpty});"
      ></div>
    {/if}
  {/if}

  {#if selfEmployed}
    <div class="image-badge" style="background-image: url('svg/self-employed.svg')"></div>
  {/if}
</div>

<style>
  .image-overlay {
    width: 100%;
    height: 220px;
    display: flex;
    position: relative;
  }

  .image-overlay.proportional-overlay {
    height: auto;
  }

  @media screen and (max-width: 320px) {
    .image-overlay {
      height: 140px
    }

    .image-overlay.proportional-overlay {
      height: auto;
    }
  }

  @media screen and (min-width: 321px) and (max-width: 520px) {
    .image-overlay {
      height: 160px
    }

    .image-overlay.proportional-overlay {
      height: auto;
    }
  }

  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .proportional-image {
    max-width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
  }

  .image-empty {
    width: 100%;
    height: 100%;
    background-image: url('/svg/smile.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .image-badge {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 15px;
    right: 20px;
    background-color: #F6F6F6;
    background-repeat: no-repeat;
    background-position: 7px 10px;
    border-radius: 50%;
  }
</style>
