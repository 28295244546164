<script lang="ts">import CardImage from './components/CardImage.svelte';
export let card;
</script>

<a href={card.payLink} class="card" target="_blank" rel="noopener noreferrer">
  <CardImage imageLink={card.projectLogoLink} selfEmployed={card.selfEmployed} />
  <div class="card-content">
    <div class="project-name">
      <h3>{card.projectName}</h3>
    </div>
  </div>
</a>

<style>
  .card {
    overflow: hidden;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  .card-content {
    padding: 20px;
    background-color: #fff;
  }

  .project-name {
    min-height: 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .project-name h3 {
    margin: 0;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #212121;
  }
</style>
