<script lang="ts">import { createEventDispatcher } from 'svelte';
import Hamburger from './Hamburger.svelte';
import { FILTER_ALL } from '../const';
export let activeFilter;
export let categories;
let isOpen = false;
const dispatch = createEventDispatcher();
$: preparedCategories = [FILTER_ALL, ...categories];
function onFilter(category) {
    if (isActive(category)) {
        return;
    }
    isOpen = false;
    dispatch('filter', category);
}
function onOpen() {
    isOpen = !isOpen;
}
function isActive(category) {
    return category.code === activeFilter.code;
}
</script>

<div class="menu-container">
  <div class="menu-control" on:click={onOpen}>
    <Hamburger />
    <p>{activeFilter.description}</p>
  </div>

  <div class="menu-popover" class:opened={isOpen}>
    {#each preparedCategories as category}
      <div class="menu-popover-item" class:active={isActive(category)} on:click={onFilter(category)}>
        {category.description}
      </div>
    {/each}
  </div>
</div>

<style>
  p {
    margin: 0 0 0 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #212121;
  }

  .menu-container {
    flex: 2;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .menu-control {
    display: flex;
    align-items: center;
  }

  .menu-popover {
    width: 210px;
    display: none;
    position: absolute;
    left: 0;
    top: 48px;
    flex-direction: column;
    padding: 15px 0;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    z-index: 1000;
  }

  .menu-popover.opened {
    display: flex;
  }

  .menu-popover-item {
    padding: 0 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #212121;
    transition: all .3s linear;
    cursor: pointer;
  }

  .menu-popover-item:hover {
    background-color: #ececec;
  }

  .menu-popover-item:not(:last-child) {
    margin-bottom: 15px;
  }

  .menu-popover-item.active {
    font-weight: 700;
  }

  @media screen and (min-width: 32rem) {
    .menu-container {
      flex: none;
    }
  }
</style>
