<script lang="ts">var _a;
import { createEventDispatcher } from 'svelte';
import throttle from '../lib/utils/throttle';
export let searchText;
export let template;
let isShownSearch;
let styles;
const dispatch = createEventDispatcher();
const onInput = throttle(() => {
    dispatch('search', searchText.trim());
}, 1000);
const onClearSearch = () => {
    if (searchText.length) {
        dispatch('search', '');
    }
    onToggleSearch();
};
const onToggleSearch = () => {
    isShownSearch = !isShownSearch;
};
$: {
    styles = Object.assign({}, (_a = template === null || template === void 0 ? void 0 : template.search) === null || _a === void 0 ? void 0 : _a.templateOne);
}
</script>

<div
  class="search-container"
  style="
    max-width: calc(100% - {2* styles.buttonMargin}px);
    {styles.buttonVerticalAlign}: 0;
    {styles.buttonHorizontalAlign}: 0;
    margin: {styles.buttonMargin}px;
  "
>
  <div
    class="search-input"
    class:opend={isShownSearch}
    style="max-width: {styles.inputMaxWidth};"
  >
    <div
      class="wrapper"
      style="
        padding: {styles.inputPadding};
        border-color: {styles.inputBorderColor};
        border-radius: {styles.inputBorderRaduis};
        background-color: {styles.inputColor};
        box-shadow: {styles.inputBoxShadow};
      "
    >
      <input
        id="search"
        bind:value={searchText}
        type="text"
        placeholder={styles.inputPlaceholderText}
        style="
          font-size: {styles.inputFontSize};
          color: {styles.inputFontColor};
          --font-family: {template?.font};
          --color-placeholder: {styles.inputPlaceholderColor};
        "
        on:input={onInput}
      />
      <span
        class="clear-icon"
        style=" margin-left: {styles.iconLeftMargin};"
        on:click={onClearSearch}
      ></span>
    </div>
  </div>
  <div
    class="search-button"
    class:closed={isShownSearch}
    style="
      padding: {styles.buttonPadding};
      border-radius: {styles.buttonBorderRadius};
      --bg-color: {styles.buttonButtonColor};
      --color-border: {styles.buttonBorderColor};
      --shadow-color: {styles.buttonBoxShadow};
      --color-hovered: {styles.buttonButtonHoverColor};
      --color-hovered-border: {styles.buttonButtonHoverBorderColor};
      --shadow-hovered-color: {styles.buttonHoverBoxShadow};
      --color-active: {styles.buttonButtonActiveColor};
      --color-active-border: {styles.buttonButtonActiveBorderColor};
      --shadow-active-color: {styles.buttonActiveBoxShadow};
      --button-icon: url('{styles.buttonIcon}');
    "
    on:click={onToggleSearch}
  >
    <span class="icon"></span>
  </div>
</div>

<style>
  input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 16px;
    color: #212121;
    background: transparent;
    box-sizing: border-box;
  }

  input::placeholder {
    font-family: var(--font-family, inherit);
    color: var(--color-placeholder, #212121);
    text-align: left;
    opacity: .35;
  }

  .search-container {
    position: fixed;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
  }

  .search-input {
    display: none;
    width: 100vw;
    transition: width .2s linear;
  }

  .search-input.opend {
    display: block;
  }

  .wrapper {
    display: flex;
    align-items: center;
    max-width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
  }

  .search-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background-color: var(--bg-color, #000);
    border-radius: 50%;
    border: 1px solid transparent;
    border-color: var(--color-border, #000000);
    box-shadow: var(--shadow-color, 0px 2px 1px rgba(0, 0, 0, 0.25));
    transition: all 0.4s linear;
    cursor: pointer;
  }

  .search-button:hover {
    background-color: var(--color-hovered, #000000);
    border-color: var(--color-hovered-border, #000000);
    box-shadow: var(--shadow-hovered-color, 0px 2px 1px rgba(0, 0, 0, 0.25));
  }

  .search-button:active {
    background-color: var(--color-active, #000000);
    border-color: var(--color-active-border, #000000);
    box-shadow: var(--shadow-active-color, 0px 2px 1px rgba(0, 0, 0, 0.25));
  }

  .icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    background-image: var(--button-icon);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .search-button.closed {
    width: 0;
    height: 0;
    opacity: 0;
    display: none;
  }

  .clear-icon {
    display: inline-block;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-image: url('/svg/clear.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
  }
</style>
