<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createEventDispatcher } from 'svelte';
import { generateRandomString } from '../../lib/utils/string.ts';
import { imageUpload } from '../../api';
export let label = 'Название поля';
export let placeholder = 'Значение в поле';
export let name = '';
export let value = '';
const LIMIT_SIZE = 5;
const dispatch = createEventDispatcher();
const inputId = generateRandomString();
function isInvalidSize(file) {
    const fileSize = file.size / 1024 / 1024;
    return fileSize > LIMIT_SIZE;
}
function handleChange($event) {
    const files = $event.target.files || $event.dataTransfer.files;
    if (!files.length) {
        return;
    }
    if (isInvalidSize(files[0])) {
        return;
    }
    uploadImage(files[0]);
}
function uploadImage(file) {
    return __awaiter(this, void 0, void 0, function* () {
        let formData = new FormData();
        formData.append('file', file);
        try {
            const data = yield imageUpload(formData);
            dispatch('upload', data);
        }
        catch (error) {
            console.error(`${error.name}: ${error.message}`);
        }
    });
}
</script>

<div class="container">
  <label for={inputId}>
    <div class="input">{value}</div>
    <div class="label">{label}</div>
    <input
      bind:value
      id={inputId}
      type="file"
      accept="image/*"
      {name}
      {placeholder}
      on:change={handleChange}
    />
  </label>
</div>

<style>
  .container {
    position: relative;
    flex-grow: 1;
  }

  .label {
    position: absolute;
    top: 10px;
    left: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #000;
  }

  input {
    display: none;
  }

  .input {
    width: 100%;
    padding: 28px 10px 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #2F80ED;
    background: #fff;
    border: 1px solid transparent;
    border-radius: 10px;
    outline: none;
    transition: all .2s linear;
    box-sizing: border-box;
    cursor: pointer;
  }

  .input::placeholder {
    color: rgba(0, 0, 0, .25)
  }
</style>
